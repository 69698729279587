
import SectionTitle from './section-title';
import ShowcaseBox from './showcase-box';

export default function ShowcaseBoxList(props) {
    const { title, boxes} = props;
    // console.log(props);
    return (
        <section className={`showcase-box-list ${props.css ? props.css : ''}`}>
            {props.featureListCss === "bg-net" && (
                <div className='skew-top'></div>
            )}
            {(title) && (
                <span
                    dangerouslySetInnerHTML={{ __html: title }}
                ></span>
            )}
            <div className='showcase-box-list-wrapper'>
                {props.boxes.map((box, idx) => (
                    <ShowcaseBox key={`showcasebox-${idx}`} {...box} />
                ))}
            </div>
        </section>
    );
}
