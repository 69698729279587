import Photo from './photo';

export default function HeroSubpage(props) {
    return (
        <section>
            <div className={`header-subpage relative ${props.headerCss ? props.headerCss : ''}`}>
                <div className="relative subpage-gradient w-full">
                    {props.image?.data ? (
                        props.imageAsBackground ? (
                            <>
                                <div
                                    className={`absolute z-1 top-0 w-full h-full opacity-50 bg-blend-soft-light bg-no-repeat bg-cover bg-center`}
                                    style={{
                                        backgroundImage: `url(${props.image.data.attributes.url})`
                                    }}
                                ></div>
                                <div className="container py-[12rem] relative z-2 ">
                                    <div className="flex items-center justify-start ">
                                        <div
                                            className={`flex flex-col w-full p-10 md:w-2/3 text-white justify-start ${props.contentCss ? props.contentCss : ''
                                                }`}
                                            dangerouslySetInnerHTML={{ __html: props.content }}
                                        ></div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="container">
                                <div className="flex items-center justify-start py-[10rem]">
                                    <div className="flex w-2/5 mx-auto md:w-1/2 relative z-20 pl-8 justify-center">
                                        <Photo image={props.image}></Photo>
                                    </div>
                                    <div
                                        className="flex flex-col w-full p-8 pt-0 md:w-2/3 text-white justify-start"
                                        dangerouslySetInnerHTML={{ __html: props.content }}
                                    ></div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="container">
                            <div className="flex items-center justify-start pt-[6rem] pb-[6rem]">
                                <div
                                    className={`flex flex-col w-full p-10 ${!props.full ? 'md:w-2/3' : ''
                                        } text-white justify-start`}
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="skew-bottom"></div>
            </div>
        </section>
    );
}
