import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import ShareIcons from './share-icons';
import BlogArticleLabels from './blog-article-labels';

function BlogArticleList(props) {
    return (
        <>
            {props.data.map((a) => (
                <article key={`article-${a.id}`} className="mb-24">
                    <Link href={`/blog/articles/${a.attributes.slug}`} title={a.attributes.title}>
                        <a className="relative flex w-full aspect-video mb-4 rounded-md overflow-hidden">
                            <Image
                                layout="fill"
                                width={a.attributes.image.data.attributeswidth}
                                height={a.attributes.image.data.attributesheight}
                                src={a.attributes.image.data.attributes.url}
                                alt={a.attributes.title}
                                sizes="(max-width: 768px) 50vw, 100vw"
                                priority
                            />
                        </a>
                    </Link>
                    <div className="info mb-4 text-maturity-300">
                        <BlogArticleLabels
                            title={a.attributes.blog_category.data.attributes.title}
                            estimate={a.attributes.estimate}
                            date={a.attributes.date}
                        ></BlogArticleLabels>
                    </div>
                    <h2 className="font-semibold !text-2xl leading-[2rem] text-left !my-0">
                        {a.attributes.title}
                    </h2>
                    <p className="pb-2 text-left">{a.attributes.summary}</p>
                    <div className="flex flex-col md:flex-row md:items-center">
                        <div className="flex flex-grow order-1 md:order-0 py-4 md:py-0">
                            <Link href={`/blog/articles/${a.attributes.slug}`}>
                                <a title={a.attributes.title} className="btn trust-border">
                                    <i className="flaticon-next text-xs mr-2"></i>
                                    Read full article
                                </a>
                            </Link>
                        </div>
                        <div className="flex order-0 md:order-1">
                            <ShareIcons
                                title={a.attributes.title}
                                url={`/blog/articles/${a.attributes.slug}`}
                                body={a.attributes.summary}
                            />
                        </div>
                    </div>
                </article>
            ))}
        </>
    );
}

export default BlogArticleList;
