import React from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'next-share';

function ShareIcons({ title, body, url }) {
    const shareUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${url}`;

    return (
        <>
            <FacebookShareButton url={shareUrl} quote={body}>
                <FacebookIcon
                    bgStyle={{ fill: '#fff' }}
                    iconFillColor={'#ccc'}
                    size={32}
                    round
                    className="mr-2 mb-2 border border-gray-300 rounded-[50px]"
                />
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon
                     bgStyle={{ fill: '#fff' }}
                    iconFillColor={'#ccc'}
                    size={32}
                    round
                    className="mr-2 mb-2 border border-gray-300 rounded-[50px]"
                />
            </TwitterShareButton>

            <LinkedinShareButton url={shareUrl} title={title} summary={body}>
                <LinkedinIcon
                     bgStyle={{ fill: '#fff' }}
                    iconFillColor={'#ccc'}
                    size={32}
                    round
                    className="mr-2 mb-2 border border-gray-300 rounded-[50px]"
                />
            </LinkedinShareButton>

            <TumblrShareButton url={shareUrl} title={title} caption={body}>
                <TumblrIcon
                     bgStyle={{ fill: '#fff' }}
                    iconFillColor={'#ccc'}
                    size={32}
                    round
                    className="mr-2 mb-2 border border-gray-300 rounded-[50px]"
                />
            </TumblrShareButton>

            <RedditShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460}>
                <RedditIcon
                     bgStyle={{ fill: '#fff' }}
                    iconFillColor={'#ccc'}
                    size={32}
                    round
                    className=" mb-2 border border-gray-300 rounded-[50px]"
                />
            </RedditShareButton>
        </>
    );
}

export default ShareIcons;
