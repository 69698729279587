import { Fade, Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Image from 'next/image';
import Photo from './photo';
export default function Slider(props) {

    const slideshowSettings = {
        autoplay: false,
        showThumbs: false,
        indicators: props.showIndicators,
        infinite: props.infiniteLoop,
        cssClass: props.sliderCss ? props.sliderCss + 'relative aspect-video w-full' : 'relative aspect-video w-full rounded-xl shadow-lg',
        duration: props.duration * 1000,
        transitionDuration: props.transitionDuration * 1000,
        arrows:props.showArrows,
        prevArrow: (
            <button className="text-gray-300 w-9 h-9 hover:text-action ml-4">
                <i className="flaticon-left-arrow pointer-events-none text-4xl"></i>
            </button>
        ),
        nextArrow: (
            <button className="text-gray-300 w-9 h-9 z-20 hover:text-action mr-4">
                <i className=" flaticon-right-arrow pointer-events-none text-4xl"></i>
            </button>
        )
    };

    return (
        props.photos.data?.length > 0 && (
            <section className={`slider ${props.sectionCss}`}>
                <div className="container px-4 xl:px-0  relative">
                    <Effect settings={slideshowSettings} animation={props.animation} >
                        {props.photos.data.map((slide, index) => (
                            <div key={slide.id.toString()} className="slider-slide">
                                {slide.attributes.caption && (
                                  
                                        <div class="slider-caption"><p>{slide.attributes.caption}</p></div>
                                  
                                )}
                                
                                <Photo layout="intrinsic" quality={100} objectFit={slide.attributes.width > slide.attributes.height ? 'cover': 'contain'}  image={{data:slide}}  />
                                {/* <Image
                                    className="object-cover w-full z-0 aspect-video"
                                    src={slide.attributes.url}
                                    layout="fill"
                                    quality={100}
                                    priority={index < 2 ? true : false}
                                    alt={slide.attributes.alternativeText} /> */}

                            </div>

                        ))}
                    </Effect>
                </div>
            </section>
        )
    );
}

const Effect = ( {animation, settings, children}) => {
 
    if (animation === 'slide') {
        return <Slide {...settings}>{children}</Slide>
    }

    if (animation === 'zoom-in') {
        return <Zoom scale={1.4} {...settings}>{children}</Zoom>
    }

    if (animation === 'zoom-out') {
        return <Zoom scale={0.7} {...settings}>{children}</Zoom>
    }

    return <Fade  {...settings}>{children}</Fade>

}
