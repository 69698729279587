import Link from 'next/link';
import React from 'react';

function pageNumbers(currentPage, totalPages, displayPages) {
    // Create an array to store page numbers
    let pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= displayPages) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= Math.ceil(displayPages / 2)) {
            startPage = 1;
            endPage = displayPages - 1;
        } else if (currentPage + Math.floor(displayPages / 2) >= totalPages) {
            startPage = totalPages - displayPages + 2;
            endPage = totalPages;
        } else {
            startPage = currentPage - Math.ceil(displayPages / 2) + 1;
            endPage = currentPage + Math.floor(displayPages / 2);
        }
    }

    if (startPage > 2) pageNumbers.push(1, '...');
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    if (endPage < totalPages - 1) pageNumbers.push('...', totalPages);

    // Return the array of page numbers
    return pageNumbers;
}

function PagionationNumber({page, i, url}) {
    return (
    <>
        {i == page && <span key={`pagination-page-active-${i.toString()}`} className="px-4 font-bold" >{i}</span>}
        {i != page && <Link key={`pagination-page-${i.toString()}`} href={`${url}/${i}`}><a className="px-4">{i}</a></Link>}
    </>)
    ;
}

function Pagination({ url, pagination: { page, pageCount } }) {
    const displayPages = 5;
    const range = pageNumbers(page, pageCount, displayPages);
    if (range.length == 1) return <></>;
    return (
        <div className="pagination">
            {page > 1 && (
                <Link key={`pagination-prev-button`} href={`${url}/${page - 1}`}>
                    <a className="pr-4">
                        <i className="flaticon-back text-[8px]"></i> Previous
                    </a>
                </Link>
            )}
            {range.map((i, index) => (
                <PagionationNumber key={`pagination-${index}`} url={url} page={page} i={i} />
            ))}
            {page < pageCount && (
                <Link key={`pagination-next-button`} href={`${url}${page ? '/' + (page + 1) : ''}`}>
                    <a className="pl-4">
                        Next <i className="flaticon-next text-[8px]"></i>
                    </a>
                </Link>
            )}
        </div>
    );
}

export default Pagination;
