
import React from 'react';
import Link from 'next/link';

function BlogCategoryList({ selected, categories }) {
    return (
        <>
            <div className="flex flex-col relative w-full mb-4 order-3 lg:order-none">
                <h2 className="flex !text-[1rem] font-bold uppercase mb-4 !mt-0">Categories</h2>
                <ul className="flex flex-col list-none border-b border-gray-100">
                    <li key={`blog-category-all`} className="!list-none !mx-0 !p-0">
                        {selected === '' && (
                            <div className="block p-4 border-t border-gray-100 w-full bg-gray-100 text-trust">
                                All categories
                            </div>
                        )}
                        {selected && (
                            <Link href={`/blog/1`}>
                                <a className="block p-4 border-t border-gray-100 w-full hover:bg-gray-100">
                                    All categories
                                </a>
                            </Link>
                        )}
                    </li>
                    {categories.map((cat) => (
                        <li key={`blog-category-${cat.id}`} className="!list-none !mx-0 !p-0">
                            {selected === cat.slug && (
                                <div className="block p-4 border-t border-gray-100 w-full bg-gray-100 text-trust">
                                    {cat.title}  ({cat.articleCount})
                                </div>
                            )}
                            {selected !== cat.slug && (
                                <Link href={`/blog/categories/${cat.slug}/1`}>
                                    <a className="block p-4 border-t border-gray-100 w-full hover:bg-gray-100">
                                        {cat.title} ({cat.articleCount})
                                    </a>
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default BlogCategoryList;
