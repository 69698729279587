
import ClientList from '../components/client-list';
import Features from '../components/features';
import Title from '../components/title';
import Layout from '../components/layout';
import HeroSection from '../components/hero-section';
import HeroSubpage from '../components/hero-subpage';
import PhotoGallery from '../components/photo-gallery';
import ContactBlock from '../components/contact-block';
import ContentBoxList from '../components/content-box-list';
import Team from '../components/team';
import Form from '../components/form';
import EventList from '../components/event-list';
import NewsList from '../components/news-list';
import FeaturedEvent from '../components/event-featured';
import BlogArticleList from '../components/blog-articles-list';
import Pagination from '../components/pagination';
import BlogCategoryList from '../components/blog-category-list';
import LargeBox from '../components/large-box';
import CtaBox from '../components/cta-box';
import JobList from '../components/job-list';
import TestimonialList from '../components/testimonial-list';
import HeroVideo from '../components/hero-video';
import { Merge } from '../lib/helper';
import { useGlobalContext } from '../lib/global-context';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useRouter } from 'next/router';
import { fetchAPI } from '../lib/api';
import SeoContext from '../lib/seo-context';
import ShowcaseBoxList from '../components/showcase-box-list';
import EnhancedContentBlock from '../components/enhanced-content-block';
import Slider from '../components/slider';

const Page = (props) => {
    const { state } = useGlobalContext();
    const global = state;
    const { pageProps } = props;

    const router = useRouter();

    const page = 1;
    if (!pageProps) {
        return '';
    }

    const seo = pageProps && pageProps.seo ? Merge(global.seo, pageProps.seo) : global.seo;
    seo.canonicalURL = `${process.env.NEXT_PUBLIC_SITE_URL}/${pageProps.slug}`;

    let footerClass = 'skew-maturity-100';

    if (pageProps) {
        pageProps.sections.map((s) => {
            if (s.__component.includes('layout.form-section')) footerClass = 'skew-maturity-100';
        });
    }

    // pageProps.sections.map((s) => {
    //     console.log(s.__component)
    // })

    return (
        <SeoContext.Provider value={seo}>
            <Layout
                footerClass={footerClass}
                languageData={{ originalSlug: pageProps.originalSlug, languages: pageProps.localizations }}
            >
                {pageProps.header[0] && pageProps.header[0].__component === "layout.hero-video" && (
                    <HeroVideo {...pageProps.header[0]}></HeroVideo>
                )}
                {pageProps.header[0] && pageProps.header[0].__component === "layout.hero" && (
                    <HeroSubpage full={true} key={`hero-subpage-header`} {...pageProps.header[0]}></HeroSubpage>
                )}
                {pageProps.sections.map((section, index) => {

                    switch (section.__component) {
                        case 'layout.feature-list':
                            return <Features key={`feature-list-${index}`} {...section} />;
                        case 'layout.large-box':
                            return <LargeBox key={`large-box-${section.__component}-${section.id}-${index}`} {...section} />;
                        case 'layout.title':
                            return <Title key={`title-box-${section.id}`} {...section} />;
                        case 'layout.cta':
                            return <CtaBox key={`cta-box-${section.id}`} {...section} />;
                        case 'layout.testimonial-list':
                            return <TestimonialList key={`test-${section.__component}-${section.id}-${index}`} {...section} />;
                        case 'layout.client-list':
                            return <ClientList key={'cli' + section.id} settings={section} />;
                        case 'layout.hero':
                            return <HeroSection key={`hero-subpage-${section.id}`} {...section}></HeroSection>;
                        case 'layout.showcase-box-list':
                            return <ShowcaseBoxList key={'showcase-box-list'} {...section} />;
                        case 'layout.slider':
                            return <Slider key={`slider-${section.id}`} {...section} />;
                        case 'layout.joblist':
                            return <JobList key={'job-list'} {...section}></JobList>;
                        case 'layout.gallery':
                            return (
                               <div className='container content-light-bg py-12'>
                                 <PhotoGallery
                                    key={`gallery-${section.id}`}
                                    {...section}
                                    className="px-10 "
                                ></PhotoGallery>
                                </div>
                            );
                        case 'layout.events-list':
                            return (
                                <div key="event-list">
                                    <FeaturedEvent events={pageProps.events} />
                                    <EventList events={pageProps.events} {...section} />
                                </div>
                            );
                        case 'layout.news-list':
                            return <NewsList key="news-list" news={pageProps.news} {...section} />;
                        case 'layout.contact-block':
                            return <ContactBlock key="contact-block" {...section}></ContactBlock>;
                        // case 'layout.form-section':
                        //     return (
                        //         <section key={'feat-' + section.id}>
                        //             <div className="relative bg-maturity-100 pt-20 mt-20">
                        //                 <div className="skew-top"></div>
                        //                 <div className="container z-20 relative -top-20">
                        //                     <GoogleReCaptchaProvider
                        //                         reCaptchaKey={process.env.NEXT_PUBLIC_RECAPCHA_SITE_KEY}
                        //                         language={router.locale}
                        //                         scriptProps={{
                        //                             async: false,
                        //                             defer: false,
                        //                             appendTo: 'head'
                        //                         }}
                        //                     >
                        //                         <Form
                        //                             key={'feat' + section.id}
                        //                             {...section.form.data.attributes.form}
                        //                         ></Form>
                        //                     </GoogleReCaptchaProvider>
                        //                 </div>
                        //             </div>
                        //         </section>
                        //     );
                        case 'layout.form-section':
                            return (
                                <section key={'form-section-' + section.id}>
                                    <div className="relative bg-gray-100 py-20 mt-20">

                                        <div className="container z-20 relative">
                                            <GoogleReCaptchaProvider
                                                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPCHA_SITE_KEY}
                                                language={router.locale}
                                                scriptProps={{
                                                    async: false,
                                                    defer: false,
                                                    appendTo: 'head'
                                                }}
                                            >
                                                <Form
                                                    key={'feat' + section.id}
                                                    {...section.form.data.attributes.form}
                                                ></Form>
                                            </GoogleReCaptchaProvider>
                                        </div>
                                    </div>
                                </section>
                            );
                        case 'layout.content-block':
                            return (
                                <section key={'content-' + section.id}>
                                    <div className="relative">
                                        <div
                                            className="container p-10 content"
                                            dangerouslySetInnerHTML={{ __html: section.content }}
                                        ></div>
                                    </div>
                                </section>
                            );
                        case 'layout.enhanced-content-block':
                            return (
                                <EnhancedContentBlock key={`slug-ecb-${section.id}`} {...section} />
                            );
                        case 'layout.team-list':
                            return (
                                <section key={'content-' + section.id}>
                                    <Team people={section.people.data}></Team>
                                </section>
                            );
                        case 'layout.content-box-list':
                            return <ContentBoxList key={`slug-ContentBoxList-${section.id}`} {...section}></ContentBoxList>;
                        case 'layout.blog-articles':
                            return (
                                <section key="blog-articles" className="container mx-auto content">
                                    <div className="flex flex-col lg:flex-row p-10">
                                        <div className="flex flex-col w-full lg:w-2/3">
                                            <div className={`articles ${page == 1 ? 'with-featured' : ''}`}>
                                                <BlogArticleList {...pageProps.articles} />
                                            </div>
                                            <div className="flex mt-10">
                                                <Pagination
                                                    url="/blog"
                                                    pagination={pageProps.articles.meta.pagination}
                                                ></Pagination>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full lg:w-1/3 lg:pl-10  relative">
                                            <div className="sticky top-24 w-full">
                                                <BlogCategoryList
                                                    selected=""
                                                    categories={pageProps.categories}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            );
                    }
                })}
            </Layout>
        </SeoContext.Provider>
    );
};

export async function getStaticPaths(ctx) {
    const paths = [];

    const { locales } = ctx;
    await Promise.all(
        locales.map(async (locale) => {
            const results = await fetchAPI('/pages', { locale, populate: ['localizations'] });

            return results.data.map((page) => {
                const isHome =
                    page.attributes.localizations.data.find((loc) => loc.attributes.slug === 'home') ||
                    page.attributes.slug === 'home';

                if (!isHome) {
                    return paths.push({
                        params: {
                            slug: page.attributes.slug
                        },
                        locale
                    });
                }
            });
        })
    );

    return {
        paths,
        fallback: true
    };
}

export async function getStaticProps(ctx) {
    const { params, locale } = ctx;
    let pageSize = 6;
    try {
        const result = await fetchAPI(`/pages/by-slug/${params.slug}`, {
            populate: 'deep,6',
            locale: locale
        });

        let getEvents = false;
        let getNews = false;
        let getArticles = false;
        result.data.attributes.sections.map((s) => {
            if (s.__component.includes('layout.events-list')) {
                getEvents = true;
            }
            if (s.__component.includes('layout.news-list')) {
                getNews = true;
            }
            if (s.__component.includes('layout.blog-articles')) {
                getArticles = true;
                // pageSize = s.articles_per_page;
            }
        });
        result.data.attributes.news = { data: [] };
        result.data.attributes.events = { data: [] };
        result.data.attributes.articles = { data: [] };
        result.data.attributes.categories = { data: [] };
        if (getEvents) {
            const events = await fetchAPI(`/events`, {
                populate: ['coverPhoto'],
                sort: ['date:desc'],
                fields: ['title', 'slug', 'date', 'shortDescription'],
                locale: locale
            });

            result.data.attributes.events = events;
        }

        if (getNews) {
            const news = await fetchAPI(`/news-insights`, {
                populate: ['coverPhoto'],
                sort: ['date:desc'],
                fields: ['title', 'slug', 'date'],
                locale: locale
            });

            result.data.attributes.news = news;
        }

        if (getArticles) {
            const articles = await fetchAPI(`/blog-articles`, {
                populate: 'deep,2',
                sort: ['id:desc'],
                locale: locale,
                pagination: {
                    page: 1,
                    pageSize
                }
            });
            result.data.attributes.articles = articles;

            const categories = await fetchAPI(`/blog-categories`, {
                populate: 'deep,2',
                sort: ['title:asc'],
                locale: locale
            });

            result.data.attributes.categories = categories.data;
        }
        if (!result) {
            return { notFound: true };
        }

        return {
            props: { pageProps: result.data.attributes },
            revalidate: Number(process.env.NEXT_PUBLIC_PAGE_REVALIDATION) || 100
        };
    } catch (e) {
        console.log('got an error', e.message);
        return { notFound: true };
    }
}

export default Page;
