import { useState } from 'react';
import Photo from './photo';
import FsLightbox from 'fslightbox-react';
import Image from 'next/image';
import { useGlobalContext } from '../lib/global-context';
import SectionTitle from './section-title';
import GetMedia from '../lib/media';

export default function PhotoGallery(props) {
    const [toggler, setToggler] = useState(false);
    const [slide, setSlide] = useState(1);
    const { state, dispatch } = useGlobalContext();

    const photos = props.photos.data.map((p) => {

        return p.attributes.mime === 'video/mp4' ? {
            type: 'video',
            src: p.attributes.url,
            caption: p.attributes.caption,
            thumb: ''
        } : {
            type: 'image',
            width: p.attributes.width,
            height: p.attributes.height,
            image: { data: p },
            src: p.attributes.url,
            thumb: p.attributes.formats.thumbnail.url,
            caption: p.attributes.caption
        };
    });


    const limit = props.visibleSlides || photos.length;
    const gallery = photos.slice(0, limit);

    return (
        <>
            {(props.title || props.desciption) && (
                <SectionTitle
                    title={props.title}
                    description={props.description}
                    className="w-full md:w-1/2"
                ></SectionTitle>
            )}

            <div
                className={`gallery relative ${props.type ? props.type : ''} ${props.className ? props.className : ''
                    } ${gallery.length === 1 ? 'cols-1' : ''}`}
            >
                {gallery.map((p, index) => (
                    <div
                        key={`slide-${index}`}
                        data-id={index}
                        className="relative w-full aspect-[4/3]"
                        onClick={() => {
                            setSlide(index + 1);
                            setToggler(!toggler);
                        }}
                    >
                        {/* <Photo
                            image={p.image}
                            layout={props.layout ? props.layout : 'fill'}
                            priority={!index ? true : false}
                        ></Photo> */}
                        {p.type === 'image' &&
                            <Image
                                src={GetMedia(p.image)}
                                alt={p.image.data.attributes.alternativeText || ''}
                                layout={'fill'}
                                priority={!index ? true : false}
                                quality={85}
                                className="object-cover"
                            ></Image>
                        }
                        {p.type === 'video' &&
                            <video muted={false} autoPlay={true} playsInline={true} src={p.src}></video>
                        }

                        {p.type === 'video' && gallery.length && <button type="button" className='absolute top-[calc(50%-2.5rem)] text-[5rem] left-[calc(50%-2.5rem)] text-white'><i className='flaticon-youtube'></i></button>}
                    </div>
                ))}
                
            </div>
            {gallery.length < photos.length && (
                    <div
                        key={'slide-last'}
                        className="continue relative w-full h-full justify-center items-center flex"
                    >
                        <button className="btn maturity-border" onClick={() => setToggler(!toggler)}>
                            View all photos
                        </button>
                    </div>
                )}
            <FsLightbox
                showThumbsOnMount={true}
                toggler={toggler}
                sources={photos.map((p) => p.src)}
                types={photos.map(p => p.type)}
                thumbs={photos.map((p) => p.thumb)}
                captions={photos.map((p) => p.caption)}
                slide={slide}
                onOpen={() =>
                    dispatch({
                        type: 'GALLERY',
                        payload: true
                    })
                }
                onClose={() =>
                    dispatch({
                        type: 'GALLERY',
                        payload: false
                    })
                }
            />
        </>
    );

}
