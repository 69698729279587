
import Image from 'next/image';
import GetMedia from '../lib/media';
import FsLightbox from 'fslightbox-react';
import { useGlobalContext } from '../lib/global-context';
import { useState } from 'react';

export default function ShowcaseBox(props) {

    const [toggler, setToggler] = useState(false);
    const [slide, setSlide] = useState(1);
    const { state, dispatch } = useGlobalContext();
    
    const photos = props.photos?.data?.length ? 
        props.photos.data.map((p) => {
            return {
                type: 'image',
                width: p.attributes.width,
                height: p.attributes.height,
                image: { data: p },
                src: p.attributes.url,
                thumb: p.attributes.formats.thumbnail.url,
                caption: p.attributes.caption
            };
        }) : [];

    const gallery = photos.length ? photos.slice(0,4) : [];

    return (
        <>        
        <div className='showcase-box container mx-auto'>
            <div className="showcase-box-wrapper">
                <div className="flex text-trust text-2xl font-bold" dangerouslySetInnerHTML={{ __html: props.title }}></div>
                <div className="flex flex-col lg:flex-row">
                    <div className="showcase-description w-full columns-1 lg:columns-2 lg:mr-8"
                        dangerouslySetInnerHTML={{ __html: props.description }}
                    ></div>
                    {(props.icons.length > 0) && (
                    <div className="flex  justify-start lg:justify-center items-start"> 
                        <div className="showcase-icons flex flex-row flex-wrap gap-4 lg:w-48">
                            {props.icons.map((i, idx) => (
                                <div key={`icon-${idx}`} className="flex items-center justify-center rounded-md bg-white bg-opacity-10 w-20 h-20 ">
                                    <i className={`flex flaticon-${i.icon}  text-5xl `}></i>
                                </div>
                            ))}
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {gallery.length > 0 && (
                <div className="flex flex-col lg:flex-row bg-black bg-opacity-20">
                  <div className="showcase-box-wrapper flex flex-col lg:flex-row">
                        <a className="flex flex-row flex-wrap gap-4 w-full lg:w-5/6 ">
                            {gallery.map((p, index) => (
                                <div key={`img-${index}`}
                                data-id={index}
                                className="flex w-[calc(50%-0.5rem)] md:w-[calc(25%-1rem)] aspect-video relative rounded-md overflow-hidden hover:opacity-40"
                                onClick={() => {
                            setSlide(index + 1);
                            setToggler(!toggler);
                        }}
                                >
                                <Image
                                        src={GetMedia(p.image)}
                                        alt={p.image.data.attributes.alternativeText || ''}
                                        layout={'fill'}
                                        priority={!index ? true : false}
                                        quality={85}
                                        className="object-cover w-full"
                                />
                                </div>
                            ))}
                        </a>
                            <div className="flex w-auto lg:w-1/6 flex-row justify-start lg:justify-end items-center py-4 ">
                            <button className="btn action-full" onClick={() => setToggler(!toggler)}>
                                View gallery
                            </button>
                        </div>
                    
                </div>
                </div>
            )}
            
        </div>
        {photos.length && (
            <FsLightbox
                showThumbsOnMount={true}
                toggler={toggler}
                sources={photos.map((p) => p.src)}
                types={photos.map(p => p.type)}
                thumbs={photos.map((p) => p.thumb)}
                captions={photos.map((p) => p.caption)}
                slide={slide}
                onOpen={() =>
                    dispatch({
                        type: 'GALLERY',
                        payload: true
                    })
                }
                onClose={() =>
                    dispatch({
                        type: 'GALLERY',
                        payload: false
                    })
                }
            />
            )}
            </>
    );
}
