import React from 'react'

function BlogArticleLabels({title, estimate, date}) {
  return (
    <div className="info mb-4">
        <span className="px-2 text-white bg-gray-400 text-[10px] uppercase inline-block rounded-lg">
            {title}
        </span>
        <span className="py-1 pl-4 pr-2 text-gray-600 text-xs  inline-block">
            {estimate} mins read
        </span>
        &bull;
        <span className="py-1 pl-2 text-gray-600  text-xs inline-block  ">
            {date}
        </span>
    </div>
  )
}

export default BlogArticleLabels