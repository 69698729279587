import SectionTitle from './section-title';

export default function EnhancedContentBlock(props) {
    return (
        <section key={'ecb-section-' + props.id} className={props.sectionCss ?? props.sectionCss}>
          {props.title && 
                <span key={`ecb-${props.id}`} className={props.titleCss} dangerouslySetInnerHTML={{ __html: props.title }}></span>
           }
            <div className="container px-4 xl:px-0">
                <div
                    className={`${props.contentCss}`}
                    dangerouslySetInnerHTML={{ __html: props.content }}
                ></div>
            </div>
        </section>
    );
}
